body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav-item {
  font-size:18px;
  text-transform:uppercase;
  height:82px;
  line-height:64px;
}
.flare {
  background:#000;
}
.flare a.nav-link {
  color:#fff !important
}
.topbarElements > div:nth-of-type(1){
  font-size:48px;
  margin:12px 32px;
  color:#3417b7
}
@media screen and (min-width:1040px){
  .flare { margin-right:-12px; }
}
@media screen and (max-width:1029px){
  .navbar-nav {
    margin-bottom:15px;
    text-align:center
  }
  .nav-item {
    background : rgb(255, 255, 255);
    margin:2px;
  }
  .nav-item :hover {
    background : rgb(202, 198, 198);
    margin:2px;
  }
  .nav-item :active  {
    background : rgb(202, 198, 198)!important;
    margin:2px;
  }
}

.boxcenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.boxcenter div {
  width: 100px;
  height: 100px;
}
   
